import { db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

export const fetchGasPriceData = async () => {
    try {
        const docRef = doc(db, "today", "gas")
        const docSnap = await getDoc(docRef);
        if (docSnap.exists) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching data: ", error);
        throw error;
    }
};

export const fetchEggPriceData = async() => {
    try {
        const docRef = doc(db, "today", "egg")
        const docSnap = await getDoc(docRef);

        if (docSnap.exists) {
            return docSnap.data();
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error fetching data: ", error);
        throw error;
    }
}
