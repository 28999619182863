// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAGpwsj0fTbxgF5w6RCSCWxh2I8Rhr-Jv0",
    authDomain: "egg-and-gas-prices.firebaseapp.com",
    projectId: "egg-and-gas-prices",
    storageBucket: "egg-and-gas-prices.firebasestorage.app",
    messagingSenderId: "116557046110",
    appId: "1:116557046110:web:86f430e44d2e575526efcf",
    measurementId: "G-BK44V9TEFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };