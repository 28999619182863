import { getAuth, signInAnonymously } from "firebase/auth";

export const anonymousAuth = async() => {
    try {
        const auth = getAuth();
        await signInAnonymously(auth);
    } catch (error) {
        console.error("Error signing in anonymously: ", error);
        throw error;
    }
}