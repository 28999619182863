// src/App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import PriceCard from "./price/PriceCard";

import { fetchEggPriceData, fetchGasPriceData } from "./firebase/queries"
import { anonymousAuth } from "./firebase/authentication";

function App() {
  const [gasPrice, setGasPrice] = useState({});
  const [eggPrice, setEggPrice] = useState({});
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
      (async () => {
          try {
              const now = new Date();
              const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/Chicago' };
              setCurrentDate(new Intl.DateTimeFormat('en-US', options).format(now));

              await anonymousAuth();
              const gas = await getGasPrice();
              const eggs = await getEggPrice();

              setEggPrice({
                  ...eggs,
                  price: `$${eggs.price}`,
              });
              setGasPrice({
                  ...gas,
                  price: `$${gas.price}`,
              });
          } catch (error) {
              console.error("Error fetching prices:", error);
              console.error("Error fetching prices:", error);
          }
      })();
  });



  // Placeholder functions; replace with actual API calls
  const getGasPrice = async() => fetchGasPriceData()
  const getEggPrice = async() => fetchEggPriceData()

  return (
      <div className="container">
        <h1>National Average Prices</h1>
          <h1>{currentDate}</h1>
        <PriceCard title="🐣 Eggs (Grade A, Large, Dozen)" price={eggPrice.price} />
        <PriceCard title="⛽️ Gas (Gallon)" price={gasPrice.price} />
      </div>
  );
}

export default App;
