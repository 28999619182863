// PriceCard.js
import React from "react";
import "./PriceCard.css";

const PriceCard = ({ title, price }) => {
    return (
        <div className="price-card">
            <h1 className="title">{title}</h1>
            <p className="price">{price}</p>
        </div>
    );
};

export default PriceCard;